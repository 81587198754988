import React from 'react';

function StreetLink({ url, street }) {
  // Assuming street is a string representing the street name
  // You can format the URL as needed based on your application
  
  return (
    <a href={url} target='_'>
      {street}
    </a>
  );
}

export default StreetLink;
