import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Assuming this is how you import jwt-decode
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AddTutorial from "./components/add-tutorial.component";
import Tutorial from "./components/tutorial.component";
import TutorialsList from "./components/tutorials-list.component";
import LinksList from "./components/links-list.component";
import ExtractsList from "./components/extracts-list.component";
import Login from "./components/login.component";
import Register from "./components/register.component";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Menubar } from "primereact/menubar";
import { Menu } from "primereact/menu";
import Account from "./components/profile.component";
import LandingPage from "./components/home.component";
import GoogleAnalytics from "./components/helpers/googleanalytics";
import PageSenseScript from "./components/helpers/zohopagesense";
import RentVsBuyTool from "./components/rentvsbuycal.component";
import PricingPage from "./components/pricing.component";
import RentalVacancyRates from "./components/rentalvacancyrates ";
import CensusPopulationTable from "./components/populationgrowth";
import PropertyTaxesTable from "./components/statepropertytaxes";
import TakuIntegration from "./components/helpers/TakuIntegration ";
import PropertyManagersTable from "./components/propertymanagers";
import LandingPageABTest from "./components/landingpage";
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          localStorage.removeItem("token");
          setIsAuthenticated(false);
          navigate("/login");
        } else {
          setUsername(decodedToken.username); // Set username from token
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        navigate("/login");
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("settings");
    setIsAuthenticated(false);
    navigate("/login");
  };

  const handleLogin = () => {
    navigate("/login");
    setShowMenu(false);
  };

  const menuItems = isAuthenticated
    ? [
        {
          label: `CashFlow Rover`,
          command: () => navigate("/"),
        },
        {
          separator: true,
        },
        {
          label: "Leads",
          icon: "pi pi-fw pi-list",
          command: () => navigate("/leads"),
        },
        {
          label: "Search",
          icon: "pi pi-fw pi-search",
          command: () => navigate("/search"),
        },
        {
          label: "Free Resources",
          icon: "pi pi-fw pi-cog",
          items: [
            {
              label: "Rent Vs Buy",
              icon: "pi pi-fw pi-chart-pie",
              command: () => navigate("/rentvbuy"),
            },{
              label: "Rental Vacancy",
              icon: "pi pi-fw pi-angle-down",
              command: () => navigate("/vacancyrate"),
            },{
              label: "Population",
              icon: "pi pi-fw pi-users",
              command: () => navigate("/populationgrowth"),
            },{
              label: "Property taxes",
              icon: "pi pi-fw pi-dollar",
              command: () => navigate("/property-taxes-state"),
            },
            {
              label: "Property manager",
              icon: "pi pi-fw pi-user-edit",
              command: () => navigate("/property-manager"),
            },{
              label: "Blog",
              icon: "pi pi-fw pi-th-large",
              command: ()  => window.location.href = "https://cashflowrover.com/",
            },
          ],
        },
        {
          label: `Welcome, ${username}`,
          icon: "pi pi-fw pi-user",
          command: () => navigate("/account"),
        },
        {
          label: "Logout",
          icon: "pi pi-fw pi-sign-out",
          command: handleLogout,
        },        
      ]
    : [
        {
          label: "Home",
          command: () => navigate("/"),
        },
        {
          label: "Pricing",
          command: () => navigate("/pricing"),
        },
        {
          label: "SignUp",
          command: () => navigate("/register"),
        },
        {
          label: "Login",
          command: () => navigate("/login"),
        },
        {
          label: "Blog",
          command: ()  => window.location.href = "https://cashflowrover.com/",
        },
      ];

  return (
    <div>
      <div>
        <div>
          <>
            <Menubar
              model={menuItems}
              end={
                isAuthenticated ? (
                  <div style={{ float: "right" }}>
                    <Menu
                      model={menuItems}
                      popup
                      className="center-menu-items" // Add custom CSS class
                      style={{ width: "14rem" }}
                      onHide={() => setShowMenu(false)}
                      appendTo={document.body}
                      visible={showMenu}
                    />
                  </div>
                ) : (
                  <></>
                )
              }
            />
          </>
        </div>
        <GoogleAnalytics />
        <PageSenseScript />
        {/* <TakuIntegration /> */}
        <Routes>
          <Route path="/search" element={<TutorialsList />} />
          <Route path="/links" element={<LinksList />} />
          <Route path="/leads" element={<ExtractsList />} />
          <Route path="/add" element={<AddTutorial />} />
          <Route path="/tutorials/:id" element={<Tutorial />} />
          <Route path="/links/:id" element={<LinksList />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/login" element={<LoginPage />} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/account" element={<Account />} />
          <Route path="/get-started" element={<LandingPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/rentvbuy" element={<RentVsBuyTool />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/vacancyrate" element={<RentalVacancyRates />} />
          <Route path="/populationgrowth" element={<CensusPopulationTable />} />
          <Route path="/property-taxes-state" element={<PropertyTaxesTable />} />
          <Route path="/property-manager" element={<PropertyManagersTable />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
