import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";
import Autocomplete from "react-google-autocomplete";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import RentVsBuyChart from "./helpers/rentvsbuycalc";

export default class RentVsBuyTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutorials: [],
      mergedTutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",
      isLoading: false,
    };
  }

  parseAddressComponents = (components) => {
    const address = {};
    if (components && components.length > 0) {
      components.forEach((component) => {
        const types = component.types;
        const longName = component.long_name;
        if (types.includes("street_number")) {
          address.streetNumber = longName;
        } else if (types.includes("route")) {
          address.street = this.formatStreet(longName);
        } else if (types.includes("locality")) {
          address.city = longName;
        } else if (types.includes("administrative_area_level_1")) {
          address.state = longName;
        } else if (types.includes("postal_code")) {
          address.zipcode = longName;
        }
      });
    }
    return address;
  };

  formatStreet = (street) => {
    const streetWords = street.split(" ");
    const formattedStreet = streetWords.slice(0, 4).join(" ");
    return formattedStreet;
  };

  onChangeSearchTitle = (place) => {
    this.setState(
      {
        searchTitle: place,
        isLoading: true,
      },
      () => {
        this.searchTitle(place.address_components);
      }
    );
  };

  searchTitle = (address_components) => {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });

    const paracall = this.parseAddressComponents(address_components);

    TutorialDataService.makeInventoryRequest(paracall)
      .then((response) => {
        this.setState(
          {
            tutorials: response.data,
          },
          () => {
            this.mergeTutorials();
          }
        );
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false });
      });
  };

  mergeTutorials = () => {
    const mergedMap = new Map();

    this.state.tutorials.forEach((tutorial) => {
      const key = `${tutorial.streetNumber}${tutorial.street}${tutorial.city}${
        tutorial.state
      }${tutorial.zipCode}${tutorial.bedrooms}${Math.floor(tutorial.bathrooms)}`
        .toLowerCase()
        .replace(/\s/g, "");

      if (mergedMap.has(key)) {
        const existingTutorial = mergedMap.get(key);
        const existingDate = new Date(existingTutorial.updateddate);
        const newDate = new Date(tutorial.updateddate);

        mergedMap.set(key, {
          ...existingTutorial,
          streetNumber: existingTutorial.streetNumber,
          street: existingTutorial.street,
          city: existingTutorial.city,
          state: existingTutorial.state,
          zipCode: existingTutorial.zipCode,
          bedrooms: existingTutorial.bedrooms,
          bathrooms: Math.max(
            parseFloat(existingTutorial.bathrooms),
            parseFloat(tutorial.bathrooms)
          ),
          squareFeet: Math.max(
            parseFloat(existingTutorial.squareFeet),
            parseFloat(tutorial.squareFeet)
          ),
          price:
            newDate > existingDate
              ? parseFloat(tutorial.price)
              : existingTutorial.price,
          sources: [...new Set([...existingTutorial.sources, tutorial.source])],
          updateddate:
            newDate > existingDate
              ? tutorial.updateddate
              : existingTutorial.updateddate,
          status:
            newDate > existingDate ? tutorial.status : existingTutorial.status,
          rentalestimate:
            newDate > existingDate
              ? tutorial.rentalestimate
              : existingTutorial.rentalestimate,
          fmr: newDate > existingDate ? tutorial.fmr : existingTutorial.fmr,
          priceHistory: [
            ...(existingTutorial.priceHistory || []),
            { date: tutorial.updateddate, price: parseFloat(tutorial.price) },
          ].sort((a, b) => new Date(b.date) - new Date(a.date)),
        });
      } else {
        mergedMap.set(key, {
          ...tutorial,
          price: parseFloat(tutorial.price),
          sources: [tutorial.source],
          priceHistory: [
            { date: tutorial.updateddate, price: parseFloat(tutorial.price) },
          ],
        });
      }
    });

    this.setState({
      mergedTutorials: Array.from(mergedMap.values()),
      isLoading: false,
    });
  };

  setActiveTutorial = (tutorial, index) => {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index,
    });
  };

  render() {
    const { mergedTutorials, currentTutorial, currentIndex, isLoading } =
      this.state;

    if (isLoading) {
      return (
        <div className="spinner-container">
          <ProgressSpinner />
        </div>
      );
    }

    return (
      <div className="container mt-3">
        <div className="list row">
          <div className="col-md-12">
            <div className="input-group mb-3">
              <Autocomplete
                className="form-control pac-target-input"
                apiKey="AIzaSyBrqfpgLEI9L-coO0abNL3pfjaS2F2TKfM"
                onPlaceSelected={this.onChangeSearchTitle}
                options={{
                  types: ["address"],
                  fields: ["address_components"],
                  componentRestrictions: { country: "us" },
                }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <h4>Address List</h4>
            <ul className="list-group">
              {mergedTutorials.map((tutorial, index) => {
                const currentPrice = tutorial.price;
                const previousPrice = tutorial.priceHistory[1]?.price;
                let priceChangeColor = "";
                let priceChangePercent = 0;

                if (previousPrice) {
                  priceChangePercent =
                    ((currentPrice - previousPrice) / previousPrice) * 100;
                  priceChangeColor = priceChangePercent < 0 ? "green" : "red";
                }

                return (
                  <li
                    className={`list-group-item ${
                      index === currentIndex ? "active" : ""
                    }`}
                    onClick={() => this.setActiveTutorial(tutorial, index)}
                    key={index}
                  >
                    <div>
                      {tutorial.streetNumber} {tutorial.street} {tutorial.city}{" "}
                      {tutorial.state} {tutorial.zipCode}
                    </div>
                    <div>
                      beds - {tutorial.bedrooms} | baths - {tutorial.bathrooms}{" "}
                      | sqFt - {tutorial.squareFeet}
                    </div>
                    <div>
                      Price - ${currentPrice.toLocaleString()}
                      {previousPrice && (
                        <span
                          style={{
                            color: priceChangeColor,
                            marginLeft: "10px",
                          }}
                        >
                          {priceChangePercent < 0 ? "▼" : "▲"}
                          {Math.abs(priceChangePercent).toFixed(2)}% ($
                          {Math.abs(
                            currentPrice - previousPrice
                          ).toLocaleString()}
                          )
                        </span>
                      )}
                    </div>
                    <div>Status - {tutorial.status}</div>
                    <div>
                      Rent - ${tutorial.rentalestimate} | FMR - ${tutorial.fmr}
                    </div>
                    <div>
                      Last updated: {moment(tutorial.updateddate).format("LL")}
                    </div>
                    {/* <div>Sources: {tutorial.sources.length}</div> */}
                  </li>
                );
              })}
              {mergedTutorials.length === 0 && (
                <li className="list-group-item">No results found.</li>
              )}
            </ul>
          </div>
          <div className="col-md-12 mt-12">
            {currentTutorial && (
              <div>
                <div >
                  <RentVsBuyChart
                    price={parseFloat(currentTutorial.price)}
                    rentalEstimate={
                      parseFloat(currentTutorial.rentalestimate) > parseFloat(currentTutorial.fmr) 
                        ? parseFloat(currentTutorial.rentalestimate) 
                        : parseFloat(currentTutorial.fmr)
                    }
                    selectedRowData={currentTutorial}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
