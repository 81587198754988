import React, { useState, useEffect } from "react";
import TutorialDataService from "../../services/tutorial.service";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown"; // Import the Dropdown component
import FilterForm from "./filterform";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Sidebar } from "primereact/sidebar";
import { Divider } from "primereact/divider";
import InfoTooltipComponent from "./InfoTooltipComponent";
import CashOnCashReturnCalculator from "./CashOnCashReturnCalculator";
import defaultThumbnail from "../../PhotoNotAvailable_Large.gif"; // Import the default image
import ListView from "./inventorylistview";
import { MultiSelect } from "primereact/multiselect";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { PrimeIcons } from "primereact/api";

export default function ExtractDTable() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const zipparam = queryParams.get("zip");
  const cityparam = queryParams.get("city");
  const stateparam = queryParams.get("state");
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [city, setCity] = useState(cityparam || "");
  const [state, setState] = useState(stateparam || "");
  const [zipcode, setZipCode] = useState(zipparam || "");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPpsqft, setMinPpsqft] = useState("");
  const [maxPpsqft, setMaxPpsqft] = useState("");
  const [status, setStatus] = useState(1);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);
  const [filter, setFilters] = useState();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [rightSidebarVisible, setRightSidebarVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [rightSidebarWidth, setRightSidebarWidth] = useState();
  const [finChips, setFinChips] = useState([]);
  const [cashOnCashReturnValue, setCashOnCashReturnValue] = useState(null);
  const [roiValue, setroiValue] = useState(null);
  const [annualPreTaxCashFlowValue, setAnnualPreTaxCashFlowValue] =
    useState(null);
  const [monthlyPreTaxCashFlowValue, setMonthlyPreTaxCashFlowValue] =
    useState(null);
  const [downPaymentPercent, setDownPaymentPercent] = useState(25);
  const [loanTermYears, setLoanTermYears] = useState();
  const handleCashOnCashReturnChange = (
    cashOnCashReturn,
    annualPreTaxCashFlow,
    roi
  ) => {
    const mcashflow = annualPreTaxCashFlow / 12;
    setCashOnCashReturnValue(cashOnCashReturn);
    setAnnualPreTaxCashFlowValue(annualPreTaxCashFlow);
    setMonthlyPreTaxCashFlowValue(mcashflow.toFixed(2));
    setroiValue(roi);
  };

  const handleResize = () => {
    // Check viewport width and set sidebar width accordingly
    if (window.innerWidth <= 600) {
      setRightSidebarWidth("100vw");
    } else {
      setRightSidebarWidth("60vw");
    }
  };

  const handlePropertyTypeChange = (e) => {
    setSelectedPropertyTypes(e.value); // Update property types array directly
  };

  // Options for the Dropdown
  const statusList = [
    { label: "Active", value: "1" },
    { label: "Sold", value: "2" },
    { label: "All", value: "" },
  ];

  // Options for the Dropdown
  const filtersList = [
    { label: "Select", value: "0" },
    { label: "Positive Cash Flow", value: "1" },
    { label: "1 Percent", value: "2" },
  ];

  const propertyTypeOptions = [
    { label: "Single Family Home", value: "1" },
    { label: "Condo", value: "2" },
    { label: "Townhouse", value: "3" },
    { label: "Multi-Family", value: "4" },
  ];

  const fetchLocation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      setZipCode(response.data.postal);
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  const fetchData = () => {
    setIsLoading(true);
    setProducts([]);

    const parsedAddress = {};
    parsedAddress.city = city;
    parsedAddress.state = state;
    parsedAddress.zipcode = zipcode;
    parsedAddress.listingStatus = status;
    parsedAddress.listingFilters = filter;
    parsedAddress.minPpsqft = minPpsqft;
    parsedAddress.maxPpsqft = maxPpsqft;
    parsedAddress.minPrice = minPrice;
    parsedAddress.maxPrice = maxPrice;
    parsedAddress.propertyTypes = selectedPropertyTypes;
    TutorialDataService.makeExtractsRequest(parsedAddress)
      .then((data) => {
        data = data.data;
        // Check if data is an array, if not, convert it to an array
        if (Array.isArray(data)) {
          // Combine streetNumber, street, city, state, and zipCode into one field
          const combinedData = data.map((item) => ({
            ...item,
            fullAddress: `${item.streetNumber} ${item.street}, ${item.city}, ${item.state} ${item.zipCode}`,
            daysonmarket: getDaysDifference(
              parseApiDateString(item.createddate),
              parseApiDateString(item.updateddate)
            ),
          }));
          setProducts(combinedData);
          setIsLoading(false); // Data fetched, loading done
        } else if (typeof data === "object") {
          // If data is an object, convert it to an array with a single element
          const combinedData = {
            ...data,
            fullAddress: `${data.streetNumber} ${data.street}, ${data.city}, ${data.state} ${data.zipCode}`,
          };
          setProducts([combinedData]);
          setIsLoading(false); // Data fetched, loading done
        } else {
          // Handle other data types or unexpected responses
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem("settings"));
    setDownPaymentPercent(savedSettings?.downpayment ?? 25);
    setLoanTermYears(savedSettings?.loanterm ?? 30);
    fetchLocation();
    fetchData();
  }, []);

  const customPriceTemplate = (selectedprice) => {
    // Safeguarding against null, undefined, or non-string values
    if (!selectedprice || typeof selectedprice !== "string") {
      return ""; // Return a placeholder or empty string in case of bad data
    }

    try {
      // Remove commas from the price string
      const priceString = selectedprice.replace(/,/g, "");

      // Convert the cleaned-up string to a number
      const price = parseFloat(priceString);

      if (!isNaN(price)) {
        // Format price with commas for thousands separator, no decimal places for whole numbers
        return price.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      } else {
        console.error("Parsed NaN from price data:", selectedprice);
        return ""; // Return empty string or placeholder if price is not valid
      }
    } catch (error) {
      // Log the error silently
      console.error("Error formatting price:", error);
      return ""; // Return empty string or a predefined error placeholder
    }
  };

  const toggleRightSidebar = () => {
    setRightSidebarVisible(!rightSidebarVisible);
  };

  const showDetailsInSidebar = (rowData) => {
    setSelectedRowData(rowData);
    calculateFin(rowData);
    setCurrentIndex(products.indexOf(rowData));
    toggleRightSidebar();
  };

  // Function to parse the date string from the API into a Date object
  const parseApiDateString = (apiDateString) => {
    // Split the date string into date and time parts
    const parts = apiDateString.split(" ");

    // Split the date part into year, month, and day
    const dateParts = parts[0].split("-");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months are zero-based in JavaScript
    const day = parseInt(dateParts[2]);

    // Split the time part into hours, minutes, seconds, and milliseconds
    const timeParts = parts[1].split(":");
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = parseInt(timeParts[2].split(".")[0]); // Remove milliseconds
    const milliseconds = parseInt(timeParts[2].split(".")[1]); // Extract milliseconds

    // Create and return the Date object
    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  };

  const getDaysDifference = (startDate, endDate) => {
    // Convert the dates to UTC to ensure accurate calculation
    const startUtc = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const endUtc = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );

    // Calculate the number of milliseconds in a day
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculate the difference in days
    return Math.floor((endUtc - startUtc) / oneDay);
  };

  const navigateRows = (direction) => {
    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < products.length) {
      const newproduct = products[newIndex];
      setSelectedRowData(newproduct);
      calculateFin(newproduct);
      setCurrentIndex(newIndex);
    }
  };

  const calculateFin = (selectedRowData) => {
    const monthlyRent =
      selectedRowData.rentalestimate !== null &&
      selectedRowData.rentalestimate !== ""
        ? selectedRowData.rentalestimate
        : selectedRowData.fmr !== null && selectedRowData.fmr !== ""
        ? selectedRowData.fmr
        : 0;

    const opexraw =
      Math.round(monthlyRent * 0.1 * 12) - Math.round(monthlyRent * 0.06 * 12);
    const griraw = Math.round(monthlyRent * 12);
    const gri = formatToLocaleString(Math.round(monthlyRent * 12));
    const noiraw = griraw - opexraw;
    const chips = [
      {
        label: "Annual Revenue",
        value: "$" + formatToLocaleString(Math.round(monthlyRent) * 12),
        hint: "",
      },
      {
        label: "Cash-on-Cash",
        value: cashOnCashReturnValue + "%",
        hint: "COC",
      },
      {
        label: "NOI",
        value: formatToLocaleString(noiraw) + " $",
        hint: "Net Operating Income",
      },
      {
        label: "Annual Vacancy",
        value: formatToLocaleString(Math.round(monthlyRent * 0.06 * 12) + " $"),
        hint: "",
      },
      {
        label: "Annual Maintanance",
        value: formatToLocaleString(Math.round(monthlyRent * 0.1 * 12) + " $"),
        hint: "",
      },
      { label: "GRI", value: gri + " $", hint: "Gross rental Income" },
    ];
    setFinChips(chips);
  };

  const firstChip = finChips.slice(0, 1);

  // Take the first two elements

  // Skip the first two elements
  const remainingChips = finChips.slice(2);

  const formatToLocaleString = (price) => {
    return price.toLocaleString();
  };

  const formatToFloat = (selectedprice) => {
    // Remove commas from the price string
    const priceString = selectedprice.replace(/,/g, "");

    // Convert the cleaned-up string to a number
    return parseFloat(priceString);
  };

  const getStatusText = (status) => {
    switch (status) {
      case "1":
      case "New Listing":
      case "NEW LISTING":
      case "Active":
        return "Active";
      case "2":
      case "5":
      case "Pending":
        return "Pending";
      case "3":
        return "sold";
      case "12":
        return "Coming Soon";
      case "6":
        return "Contingent";
      default:
        return status;
    }
  };

  // Function to render details in a formatted list
  const renderDetails = () => (
    <div>
      <div className="">
        <div className="flex-wrap items-center text-base font-bold bg-white md:flex md:py-1 md:text-lg mb-1 sticky-image">
          <div className="pl-1 m-1 ">
            <span className="status-label">
              {getStatusText(selectedRowData.status)}
            </span>
            <img
              src={selectedRowData.thumbnail || defaultThumbnail}
              alt="Thumbnail"
              className="thumbnail-img"
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop in case the default image also fails
                e.target.src = defaultThumbnail;
              }}
            />
            <Button
              icon="pi pi-times"
              className="close-button mr-3"
              onClick={toggleRightSidebar}
            />
          </div>
        </div>

        <ul className="list-none p-0 m-0 flex-wrap">
          <li className="md:flex-row md:align-items-center md:justify-content-between mb-2 ml-2">
            <div>
              <div className="mt-1 flex-col text-900 price-bold-and-big">
                ${customPriceTemplate(selectedRowData.price)}
              </div>
              <div className="mt-1 flex-col text-base">
                {selectedRowData.bedrooms} bds | {selectedRowData.bathrooms} ba
                | {selectedRowData.year_built} built |{" "}
                {selectedRowData.squareFeet} sqft
              </div>
              <div className="mt-1 text-600">
                <a
                  href={selectedRowData.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedRowData.fullAddress}
                </a>
              </div>
            </div>
          </li>
          <li className="md:flex-row md:align-items-center md:justify-content-between mb-2">
            <div className="mt-1 text-lg md:mt-1">
              <div className="flex-container mt-1">
                {selectedRowData.price && (
                  <div className="flex-col p-3rem chip items-center bg-gray-100 border border-gray-400 rounded-md md:text-sm">
                    <p className="mb-0">
                      $
                      {formatToLocaleString(
                        formatToFloat(selectedRowData.price) *
                          (downPaymentPercent / 100)
                      )}{" "}
                      Initial Capital
                    </p>
                  </div>
                )}
                {selectedRowData.ppsqft && (
                  <div className="flex-col p-3rem chip items-center bg-gray-100 border border-gray-400 rounded-md md:text-sm">
                    <p className="mb-0">${selectedRowData.ppsqft}/sqft</p>
                  </div>
                )}
                {selectedRowData.property_type && (
                  <div className="flex-col p-3rem chip items-center bg-gray-100 border border-gray-400 rounded-md md:text-sm">
                    <p className="mb-0">{selectedRowData.property_type}</p>
                  </div>
                )}
                {selectedRowData.year_built && (
                  <div className="flex-col p-3rem chip items-center bg-gray-100 border border-gray-400 rounded-md md:text-sm">
                    <p className="mb-0">
                      {" "}
                      Built in {selectedRowData.year_built}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </li>
          {(selectedRowData.rentalestimate || selectedRowData.fmr) && (
            <li className="md:align-items-center md:justify-content-between mb-2 ml-2">
              <Divider />
              <div>
                <div>
                  <span className="mr-2 mb-1 md:mb-0 text-xl font-bold md:text-2xl">
                    Projected returns
                  </span>
                </div>
                <div className="flex-row">
                  <div className="flex-container mt-1">
                    {firstChip.map((item) => (
                      <div className="flex-col chip px-4 py-3rem bg-white border border-gray-400 rounded-md md:px-6 md:py-4  pl-1 ml-0">
                        <p className="text-base font-bold md:text-lg mb-1">
                          <InfoTooltipComponent description={item.hint} />{" "}
                          {item.label}
                        </p>
                        <p className="text-xl font-bold truncate md:text-2xl  mb-0">
                          {item.value}
                        </p>
                      </div>
                    ))}
                    <div className="flex-col chip px-4 py-3rem bg-white border border-gray-400 rounded-md md:px-6 md:py-4  pl-1 ml-0">
                      <p className="text-base font-bold md:text-lg mb-1">
                        <InfoTooltipComponent description={"COC"} />{" "}
                        Cash-on-Cash
                      </p>
                      <p className="text-xl font-bold truncate md:text-2xl  mb-0">
                        {cashOnCashReturnValue} %
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="flex-container mt-1">
                    <div className="chip mt-1 flex-col bg-gray-100 border border-gray-400 rounded-md  pl-1 ml-0">
                      <InfoTooltipComponent description="Cash Flow" /> Cash
                      Flow: {monthlyPreTaxCashFlowValue} $
                    </div>
                    <div className="chip mt-1 flex-col bg-gray-100 border border-gray-400 rounded-md  pl-1 ml-0">
                      <InfoTooltipComponent description="ROI" /> ROI: {roiValue}{" "}
                      %
                    </div>
                    {remainingChips.map((item) => (
                      <div className="chip mt-1 flex-col bg-gray-100 border border-gray-400 rounded-md  pl-1 ml-0">
                        <InfoTooltipComponent description={item.hint} />{" "}
                        {item.label}: {item.value}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </li>
          )}
          <Divider />

          <li className="mb-2  ml-2">
            {(selectedRowData.rentalestimate || selectedRowData.fmr) && (
              <CashOnCashReturnCalculator
                purchaseprice={formatToFloat(selectedRowData.price)}
                rentalestimate={
                  selectedRowData.rentalestimate || selectedRowData.fmr
                }
                selectedRowData={selectedRowData}
                onCashOnCashReturnChange={handleCashOnCashReturnChange}
              />
            )}
          </li>
          <Divider />
          <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-2 ml-2">
            <div>
              <span className="text-900 font-medium mr-2 mb-1 md:mb-0">
                Days On Market
              </span>
              <div className="mt-1 text-600">
                {selectedRowData.daysonmarket || "today"}
              </div>
            </div>
          </li>
          <Divider />
          {selectedRowData.linkedid && (
            <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-2 ml-2">
              <div>
                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">
                  Linked Id
                </span>
                <div className="mt-1 text-600">
                  <a
                    href={"/links/" + selectedRowData.linkedid}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedRowData.linkedid}
                  </a>
                </div>
              </div>
              {/* <div
                className="flex align-items-center justify-content-center bg-green-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-link text-green-500 text-xl"></i>
              </div> */}
            </li>
          )}
        </ul>
      </div>
      <Divider />
    </div>
  );

  const header = (
    <Accordion>
      <AccordionTab header="filters">
        <div className="formgrid grid">
          <div className="field col-12 md:col-3">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.MAP_MARKER}></i>{" "}
              </span>
              <InputText
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="w-full"
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.GLOBE}></i> {/* PrimeIcons example */}
              </span>
              <InputText
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
                className="w-full"
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.ENVELOPE}></i>{" "}
                {/* PrimeIcons example */}
              </span>
              <InputText
                placeholder="ZipCode"
                value={zipcode}
                onChange={(e) => setZipCode(e.target.value)}
                className="w-full"
                type="number"
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.INFO_CIRCLE}></i>
              </span>
              <Dropdown
                value={status}
                options={statusList}
                onChange={(e) => setStatus(e.value)}
                placeholder="Select a Status"
                className="w-full"
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.FILTER}></i>
              </span>
              <Dropdown
                value={filter}
                options={filtersList}
                onChange={(e) => setFilters(e.value)}
                placeholder="Select a Filter"
                className="w-full"
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.BUILDING}></i>
              </span>
              <MultiSelect
                value={selectedPropertyTypes}
                options={propertyTypeOptions}
                onChange={handlePropertyTypeChange}
                placeholder="Select Property Types"
                className="w-full"
                display="chip" // Displays selected items as chips
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.MONEY_BILL}></i>{" "}
                {/* PrimeIcons example */}
              </span>
              <InputText
                placeholder="Min Price"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
                className="w-full"
                type="number"
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.MONEY_BILL}></i>{" "}
                {/* PrimeIcons example */}
              </span>
              <InputText
                placeholder="Max Price"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
                className="w-full"
                type="number"
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.CALCULATOR}></i>{" "}
                {/* PrimeIcons example */}
              </span>
              <InputText
                placeholder="Min $/SqFt"
                value={minPpsqft}
                onChange={(e) => setMinPpsqft(e.target.value)}
                className="w-full"
                type="number"
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.CALCULATOR}></i>{" "}
                {/* PrimeIcons example */}
              </span>
              <InputText
                placeholder="Max $/SqFt"
                value={maxPpsqft}
                onChange={(e) => setMaxPpsqft(e.target.value)}
                className="w-full"
                type="number"
              />
            </div>
          </div>

          <div className="field col-12 md:col-12 md:offset-4">
            <Button
              label="Apply"
              onClick={fetchData}
              className="p-button-text w-full"
            />
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  );

  const handleNodeSelect = (nodeData) => {
    showDetailsInSidebar(nodeData);
    handleResize();
  };

  return (
    <div>
      {header}
      {FilterForm}
      {isLoading ? (
        <div
          className="flex align-items-center justify-content-center"
          style={{ height: "200px" }}
        >
          <ProgressSpinner />
        </div>
      ) : (
        <div>
          <div>
            <ListView products={products} onNodeSelect={handleNodeSelect} />
          </div>

          {/* <DataTable
            value={products}
            stripedRows
            scrollable
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 50]}
            filterDisplay="menu"
            responsive
            rowTemplate={rowTemplate}
          >
            <Column
              field="fullAddress"
              header="Address"
              body={customColumnTemplate}
              filter
              filterPlaceholder="Search by name"
            ></Column>
            <Column
              field="price"
              header="Price"
              sortable
              body={customColumnTemplate}
            ></Column>
            <Column
              field="downpayment"
              header="25% DownPayment"
              body={customColumnTemplate}
              sortable
            ></Column>
            <Column
              field="bedbath"
              header="Bed/Bath"
              body={customColumnTemplate}
            ></Column>
            <Column
              field="squareFeet"
              header="Square Feet"
              body={customColumnTemplate}
            ></Column>
            <Column
              field="ppsqft"
              header="Price/Sqft"
              dataType="numeric"
              filter
              filterPlaceholder="Search by psqft"
              sortable
              body={customColumnTemplate}
            ></Column>
            <Column
              field="rentalestimate"
              header="Rental Value"
              dataType="numeric"
              filter
              filterPlaceholder="Search by psqft"
              sortable
              body={customColumnTemplate}
            ></Column>            
            <Column
              field="daysonmarket"
              header="Days"
              body={customColumnTemplate}
              dataType="number"
              sortable
            ></Column>
            <Column
              field="updateddate"
              header="Updateddate"
              body={customColumnTemplate}
              sortable
            ></Column>
          </DataTable> */}

          <Sidebar
            visible={rightSidebarVisible}
            onHide={toggleRightSidebar}
            position="right"
            style={{ width: rightSidebarWidth }}
          >
            {selectedRowData && (
              <div>
                <div className="sidebar-content">
                  <Divider />
                  {renderDetails()}

                  <Divider />
                  <Button
                    label="Previous"
                    icon="pi pi-chevron-left"
                    className="p-button-link pl-0 ml-2"
                    onClick={() => navigateRows(-1)}
                    disabled={currentIndex === 0}
                  />
                  <Button
                    label="Next"
                    icon="pi pi-chevron-right"
                    className="p-button-link"
                    iconPos="right"
                    onClick={() => navigateRows(1)}
                    disabled={currentIndex === products.length - 1}
                  />
                </div>
              </div>
            )}
          </Sidebar>
        </div>
      )}
    </div>
  );
}
