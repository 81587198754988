import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import TutorialDataService from "../services/tutorial.service";
import { useNavigate } from 'react-router-dom';

const CensusPopulationTable = () => {
  const [populationData, setPopulationData] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(20);
  const [first, setFirst] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchPopulationData();
  }, [first, rows, sortField, sortOrder]);

  const fetchPopulationData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://ipmc-api.onrender.com/api/lookup/population-growth?limit=${rows}&offset=${first}${
          sortOrder ? `&sort=${sortOrder === 1 ? "asc" : "desc"}` : ""
        }`
      );
      const data = await response.json();
      setPopulationData(data.data);
      setTotalRecords(data.total);
      setLoading(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const navigate = useNavigate(); // Updated hook for navigation

  const navigateToExtracts = (city,stateabb) => {
    // Navigate to the extracts page with the city name
    navigate(`/leads?city=${city}&state=${stateabb}`);
  };

  const cityBodyTemplate = (rowData) => {
    const cleanedName = rowData.NAME.replace(/^Balance of\s*/, '').replace(/\s*\(pt\.\)$/, '');
    return (
      <a href={`/leads?city=${cleanedName}&state=${rowData.stateabb}`} onClick={(e) => {
        e.preventDefault();
        navigateToExtracts(cleanedName, rowData.stateabb );
      }}>
        {rowData.NAME}, {rowData.stateabb?.toUpperCase()}
      </a>
    );
  };

  const fetchPopulationDataByCity = async (city) => {
    setLoading(true);
    try {
        TutorialDataService.getPopulationDataByCity(city)
        .then((data) => {
          setPopulationData(data.data);
          setTotalRecords(data.data.length);
          setLoading(false);
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to fetch data",
            life: 3000,
          });
          setLoading(false);
        });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const onSearch = () => {
    if (searchCity.trim() !== "") {
      fetchPopulationDataByCity(searchCity.trim());
    } else {
      fetchPopulationData();
    }
  };

  const onPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const onSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
  };

  return (
    <div className="datatable-filter-demo">
      <div className="card m-2">
        <h3>Census Population Data</h3>
        <div className="p-grid p-justify-between p-ai-center mb-3 pl-1">
          <div className="p-col">
            <InputText
              value={searchCity}
              onChange={(e) => setSearchCity(e.target.value)}
              placeholder="Search by City"
            />
            <Button label="Search" icon="pi pi-search" onClick={onSearch} />
          </div>
        </div>
        <Toast ref={toast} />
        <DataTable
          value={populationData}
          paginator
          rows={rows}
          first={first}
          totalRecords={totalRecords}
          lazy
          loading={loading}
          responsiveLayout="scroll"
          onPage={onPage}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
          className="mb-3 pl-1"
        >
          <Column field="NAME" header="City" body={cityBodyTemplate}></Column>
          <Column
            field="POPESTIMATE2023"
            header="Population Estimate 2023"            
          ></Column>
          <Column
            field="PERCENT_CHANGE"
            header="Population Growth (%)"
            sortable
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default CensusPopulationTable;
