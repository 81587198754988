import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const Pricing = () => {
    const startedPlanFeatures = [
        "Proin fermentum leo",
        "Ullamcorper morbi tincidunt",
        "Adipiscing enim eu turpis",
        "Velit ut tortor pretium",
        "Faucibus vitae aliquet nec",
        "Tristique senectus et netus",
        "Elit sed vulputate mi sit",
        "Et netus et malesuada"
    ];

    const professionalPlanFeatures = [
        "Proin fermentum leo",
        "Ullamcorper morbi tincidunt",
        "Adipiscing enim eu turpis",
        "Velit ut tortor pretium",
        "Faucibus vitae aliquet nec",
        "Tristique senectus et netus",
        "Elit sed vulputate mi sit",
        "Et netus et malesuada"
    ];

    const renderFeatures = (features) => {
        return features.map((feature, index) => (
            <li key={index} className="p-mb-2">
                <i className="pi pi-check p-mr-2"></i>{feature}
            </li>
        ));
    };

    return (
        
<div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
    <div className="text-900 font-bold text-6xl mb-4 text-center">Pricing Plans</div>
    <div className="text-700 text-xl mb-6 text-center line-height-3">Discover the best rental properties with ease and maximize your cashflow with plans starting at $19.99/month or save 20% with an annual subscription.</div>

    <div className="grid">
        <div className="col-12 lg:col-6">
            <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
                    <div className="text-900 font-medium text-xl mb-2">Monthly</div>
                    <div className="text-600">Stay agile with our monthly plan—affordable access, no long-term commitments.</div>
                    <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                    <div className="flex align-items-center">
                        <span className="font-bold text-2xl text-900">$19.99</span>
                        <span className="ml-2 font-medium text-600">per month</span>
                    </div>
                    <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                    <ul className="list-none p-0 m-0 flex-grow-1">
                        <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span className="text-900">Market Analysis Tools</span>
                        </li>
                        <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span className="text-900">Property Comparison Features</span>
                        </li>
                        <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span className="text-900">Cash Flow Forecasts</span>
                        </li>
                        <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span className="text-900">Flexible Subscription</span>
                        </li>
                    </ul> 
                    <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                    <a href="https://cashflowrover.lemonsqueezy.com/buy/43a581bc-69bb-405e-b9f8-51c77c2b219d" target="_blank" rel="noopener noreferrer">
                        <Button label="Get Started" className="p-3 w-full p-button" />
                    </a>
                </div>
            </div>
        </div>

        <div className="col-12 lg:col-6">
            <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
                    <div className="text-900 font-medium text-xl mb-2">Yearly</div>
                    <div className="text-600">Commit to success with our yearly plan—save big with 20% off and unlock full access!</div>
                    <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                    <div className="flex align-items-center">
                        <span className="font-bold text-2xl text-900">$191.90</span>
                        <span className="ml-2 font-medium text-600">per Year</span>
                    </div>
                     <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                    <ul className="list-none p-0 m-0 flex-grow-1">
                        <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span className="text-900">All Monthly Plan Features</span>
                        </li>
                        <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span className="text-900">Enhanced Data Insights</span>
                        </li>
                        <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span className="text-900">Rental Income Estimations</span>
                        </li>
                        <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span className="text-900">Priority Customer Support</span>
                        </li>
                    </ul> 
                    <hr className="mb-3 mx-0 border-top-1 border-none surface-border" />
                    <a href="https://cashflowrover.lemonsqueezy.com/buy/3f3a05bc-ab67-4ef8-a973-33d5b46a788f" target="_blank" rel="noopener noreferrer">
                        <Button label="Get Started" className="p-3 w-full p-button" />
                    </a>
                </div>
            </div>
        </div>

       
    </div>
</div>
    
    );
};

export default Pricing;
