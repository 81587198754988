import React, { useState } from "react";
import http from "../http-common";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    try {
      const response = await http.post(
        "/user/register",
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      // Store token in localStorage
      localStorage.setItem("token", data.token);
      window.location.href = "/leads";
    } catch (error) {
      console.error("Registration failed:", error);
      // Handle login failure
    }
  };

  return (
    <div>
      <div className="p-grid p-nogutter landing-page">
        <div className="p-col-12 p-md-6 p-lg-6 left-section">
          <h1 className="headline">Supercharge Your Next Research for Cashflowing Rental Property</h1>
        </div>
      </div>
      <div className="flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Welcome Back
            </div>
            <span className="text-600 font-medium line-height-3">
              Already have an account?
            </span>
            <a
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
              href={"/login/"}
            >
              Login!
            </a>
          </div>

          <div>
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email
            </label>
            <InputText
              id="email"
              type="text"
              placeholder="Email address"
              className="w-full mb-3"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Password
            </label>
            <InputText
              id="password"
              type="password"
              placeholder="Password"
              className="w-full mb-3"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              label="Register"
              icon="pi pi-user"
              className="w-full"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
