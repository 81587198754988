import React, { useEffect } from 'react';

const PageSenseScript = () => {
  useEffect(() => {
    (function (w, s) {
      var e = document.createElement('script');
      e.type = 'text/javascript';
      e.async = true;
      e.src = 'https://cdn.pagesense.io/js/cnmibrqe/f6958d7c87804d4fb6537ada518acf9f.js';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(e, x);
    })(window, 'script');
  }, []);

  return null;
};

export default PageSenseScript;
