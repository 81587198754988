import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import 'primereact/resources/themes/saga-blue/theme.css';  // Import your chosen theme
import 'primereact/resources/primereact.min.css';          // PrimeReact CSS
import 'primeicons/primeicons.css';                        // PrimeIcons CSS
import 'primeflex/primeflex.css';                          // PrimeFlex CSS (for utility classes)

const InfoTooltipComponent = ({ description }) => {
  return (
    <span>
      <i 
        className="pi pi-info-circle"
        data-pr-tooltip={description}
        data-pr-position="top"
        style={{ fontSize: '.75em', cursor: 'pointer' }}
      />
      <Tooltip target=".pi-info-circle" />
    </span>
  );
};

export default InfoTooltipComponent;
