import React from "react";
import useAuth from "./authhelper"; // Adjust the path as necessary

const withAuth = (Component) => {
  return (props) => {
    useAuth();
    return <Component {...props} />;
  };
};

export default withAuth;
