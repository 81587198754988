export const calculateReturns = ({
    purchasePrice,
    downPaymentPercent,
    interestRate,
    loanTermYears,
    monthlyRent,
    vacancyRatePercent,
    maintenancePercent,
    operatingExpensePercent,
    appreciationRate,
    holdingPeriodYears,
    sellingCostsPercent
}) => {
    const downPayment = purchasePrice * (downPaymentPercent / 100);
    const loanAmount = purchasePrice - downPayment;
    const monthlyInterestRate = interestRate / 12 / 100;
    const numberOfPayments = loanTermYears * 12;

    const monthlyMortgagePayment =
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    const grossAnnualRent = monthlyRent * 12;
    const annualVacancyLoss = grossAnnualRent * (vacancyRatePercent / 100);
    const netAnnualRent = grossAnnualRent - annualVacancyLoss;

    const annualMortgagePayment = monthlyMortgagePayment * 12;
    const annualMaintenanceCosts =
        monthlyRent * (maintenancePercent / 100) * 12;
    const annualOperatingExpenses =
        monthlyRent * (operatingExpensePercent / 100) * 12;

    const annualNetOperatingIncome = netAnnualRent - annualOperatingExpenses;
    const annualPreTaxCashFlow =
        annualNetOperatingIncome - annualMortgagePayment - annualMaintenanceCosts;

    const capRate = (annualNetOperatingIncome / purchasePrice) * 100;
    const coC = (annualPreTaxCashFlow / downPayment) * 100;

    // ROI calculation
    const sellingPrice = purchasePrice * Math.pow((1 + appreciationRate / 100), holdingPeriodYears);
    const sellingCosts = sellingPrice * (sellingCostsPercent / 100);

    const totalIncome = (annualPreTaxCashFlow * holdingPeriodYears) + (sellingPrice - sellingCosts);
    const totalInvestmentCost = downPayment + (annualMortgagePayment * holdingPeriodYears) + (annualMaintenanceCosts * holdingPeriodYears) + (annualOperatingExpenses * holdingPeriodYears);

    const roi = ((totalIncome - totalInvestmentCost) / totalInvestmentCost) * 100;

    return {
        annualPreTaxCashFlow: annualPreTaxCashFlow.toFixed(2),
        cashOnCashReturn: coC.toFixed(2),
        ROI: roi.toFixed(2),
        capRate: capRate.toFixed(2)
    };
};
