import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  
  // Function to remove trailing slash from a path
  const removeTrailingSlash = (path) => {
    return path.endsWith("/") && path !== "/" ? path.slice(0, -1) : path;
  };

  // Function to check if the current path matches any in the array
  const shouldStayOnCurrentPage = (currentPath) => {
    const normalizedCurrentPath = removeTrailingSlash(currentPath);
    return stayOnCurrentPagePaths.some(
      (path) => normalizedCurrentPath === removeTrailingSlash(path)
    );
  };

  // Define an array of paths where the user should stay on the current page
  const stayOnCurrentPagePaths = [
    "/get-started",
    "/",
    "/register",
    "/login",
    "/pricing",
    "/blog",
    
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          // Token has expired
          localStorage.removeItem("token");
          setUser(null);
          navigate("/login");
        } else {
          // Token is valid
          setUser(decodedToken);
          if (stayOnCurrentPagePaths.includes(location.pathname)) {
            navigate("/leads");
          }
        }
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("token");
        // No token found
        setUser(null);     

        // Function to remove trailing slash from a path
        const removeTrailingSlash = (path) => {
          return path.endsWith("/") && path !== "/" ? path.slice(0, -1) : path;
        };

        // Function to check if the current path matches any in the array
        const shouldStayOnCurrentPage = (currentPath) => {
          const normalizedCurrentPath = removeTrailingSlash(currentPath);
          return stayOnCurrentPagePaths.some(
            (path) => normalizedCurrentPath === removeTrailingSlash(path)
          );
        };

        if (shouldStayOnCurrentPage(location.pathname)) {
          // Stay on the current page
        } else {
          // Redirect to login for all other pages
          navigate("/login");
        }
      }
    } else {
      // No token found
      setUser(null);

      // Function to remove trailing slash from a path
      const removeTrailingSlash = (path) => {
        return path.endsWith("/") && path !== "/" ? path.slice(0, -1) : path;
      };

      // Function to check if the current path matches any in the array
      const shouldStayOnCurrentPage = (currentPath) => {
        const normalizedCurrentPath = removeTrailingSlash(currentPath);
        return stayOnCurrentPagePaths.some(
          (path) => normalizedCurrentPath === removeTrailingSlash(path)
        );
      };

      if (shouldStayOnCurrentPage(location.pathname)) {
        // Stay on the current page
      } else {
        // Redirect to login for all other pages
        navigate("/login");
      }
    }
  }, [navigate]);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
