import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // Ensure you import the CSS
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons

function FilterForm() {
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [minPpsqft, setMinPpsqft] = useState('');
    const [maxPpsqft, setMaxPpsqft] = useState('');

    const fetchData = () => {
        // Implement your fetch logic here
        console.log("Filters Applied", { city, state, zipcode, minPrice, maxPrice, minPpsqft, maxPpsqft });
    };

    return (
        <div className="formgrid grid">
          <div className="field col-12 md:col-4">
            <InputText
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-4">
            <InputText
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-4">
            <InputText
              placeholder="ZipCode"
              value={zipcode}
              onChange={(e) => setZipCode(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-6">
            <InputText
              placeholder="Min Price"
              value={minPrice}
              onChange={(e) => setMinPrice(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-6">
            <InputText
              placeholder="Max Price"
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-6">
            <InputText
              placeholder="Min $/SqFt"
              value={minPpsqft}
              onChange={(e) => setMinPpsqft(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-6">
            <InputText
              placeholder="Max $/SqFt"
              value={maxPpsqft}
              onChange={(e) => setMaxPpsqft(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-4 md:offset-4">
            <Button label="Apply" onClick={fetchData} className="p-button-text w-full" />
          </div>
        </div>
    );
}

export default FilterForm;
