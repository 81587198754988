import http from "../http-common";

class TutorialDataService {
  getAll() {
    return http.get("/inventory");
  }

  get(id) {
    return http.get(`/inventory/${id}`);
  }

  getRentalVacancyRateByZip(zip) {
    return http.get(`/lookup/vacancy/${zip}`);
  }

  getPopulationDataByCity(city) {
    return http.get(`/lookup/population-growth/search?city=${city}`);
  }


  findByTitle(title) {
    return http.get(`/inventory?title=${title}`);
  }

  makeInventoryRequest(queryParams) {
    // Construct the base URL
    let url = '/inventory';
  
    // Construct an array to store the query parameters
    const queryParamsArray = [];
  
    // Loop through the provided queryParams object and add each parameter to the array
    for (const key in queryParams) {
      if (queryParams[key]) {
        queryParamsArray.push(`${key}=${queryParams[key]}`);
      }
    }
  
    // If there are any query parameters, concatenate them with '&' and append to the URL
    if (queryParamsArray.length > 0) {
      url += '?' + queryParamsArray.join('&');
    }
  
    // Make the HTTP GET request
    return http.get(url);
  }

  getTopRecentLinks() {
    return http.get("/links");
  }

  getLink(id) {
    return http.get(`/links/${id}`);
  }

  getTopRecentExtracts() {
    return http.get("/extracts");
  }

  getExtract(id) {
    return http.get(`/extracts/${id}`);
  }

  getKpis() {
    return http.get("/kpis");
  }

  makeExtractsRequest(queryParams) {
    // Construct the base URL
    let url = '/extracts';
  
    // Construct an array to store the query parameters
    const queryParamsArray = [];
  
    // Loop through the provided queryParams object and add each parameter to the array
    for (const key in queryParams) {
      if (queryParams[key]) {
        queryParamsArray.push(`${key}=${queryParams[key]}`);
      }
    }
  
    // If there are any query parameters, concatenate them with '&' and append to the URL
    if (queryParamsArray.length > 0) {
      url += '?' + queryParamsArray.join('&');
    }
  
    // Make the HTTP GET request
    return http.get(url);
  }

}

export default new TutorialDataService();