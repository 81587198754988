import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { useNavigate } from 'react-router-dom';

const PropertyTaxesTable = () => {
  const [propertyTaxes, setPropertyTaxes] = useState([]);
  const [searchState, setSearchState] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(20);
  const [first, setFirst] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchPropertyTaxes();
  }, [first, rows, sortField, sortOrder]);

  const fetchPropertyTaxes = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://ipmc-api.onrender.com/api/lookup/property-taxes-state`
      );
      const data = await response.json();
      setPropertyTaxes(data);
      setTotalRecords(data.length);
      setLoading(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const navigateToDetails = (State) => {
    navigate(`/leads?state=${State}`);
  };

  const stateBodyTemplate = (rowData) => {
    return (
      <a href={`/leads?state=${rowData.StateAbb}`} onClick={(e) => {
        e.preventDefault();
        navigateToDetails(rowData.StateAbb);
      }}>
        {rowData.State}
      </a>
    );
  };

  return (
    <div className="datatable-filter-demo">
      <div className="card m-2">
        <h3>Property Taxes by State</h3>        
        <Toast ref={toast} />
        <DataTable
          value={propertyTaxes}
          sortMode="multiple"
          className="mb-3 pl-1"
          paginator rows={10} 
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          <Column field="State" header="State" body={stateBodyTemplate}></Column>
          <Column field="Average_Home_Value" header="Average Home Value" sortable></Column>
          <Column field="Property_Tax_Rate" header="Tax Rate (%)" sortable></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default PropertyTaxesTable;
