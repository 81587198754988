import React, { Component } from "react";
import ExtractDTable from "./helpers/extractdtable";
import Kpis from "./helpers/kpis";
import withAuth from "./helpers/authhoc"; // Adjust the path as necessary


class ExtractsList extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //this.retrieveTutorials();
  }

  render() {

    return (
      <div className="layout-main-container">
         <div className="text-600 font-bold text-6xl mb-4 text-center" > </div>
        <div className="row">
          <div className="col-md-12">
            {/* <Kpis /> */}
          </div>         
        </div>
        <div className="row">         
          <div className="col-md-12">
            <ExtractDTable />
          </div>
        </div>
      </div>
    );
  }
}

export default withAuth(ExtractsList);

