import React, { useState, useEffect } from "react";
import { Tree } from "primereact/tree";
import defaultThumbnail from "../../PhotoNotAvailable_Large.gif"; // Import the default image
import "../../ListView.css"; // Custom CSS for styling
import { Paginator } from "primereact/paginator";
import { formatPrice } from "./utils";
import { calculateReturns } from "../../common/helpers/functions";

const ListView = ({ products, onNodeSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    setNodes(buildNodes(products));
  }, [products]);


  const formatCashFlow = (cashFlow) => {
    return cashFlow < 0
      ? `(${Math.abs(cashFlow)?.toFixed(2)})`
      : cashFlow?.toFixed(2);
  };

  const getCashFlowStyle = (cashFlow) => {
    return {
      color: cashFlow < 0 ? 'red' : '#007bff'
    };
  };

  const calcCaprate = (product) => {
    const monthlyRent = (product.rentalestimate !== null && product.rentalestimate !== "") ? 
                   product.rentalestimate :
                   (product.fmr !== null && product.fmr !== "" ? product.fmr : 0);

    const savedSettings = JSON.parse(localStorage.getItem("settings"));
    const results = calculateReturns({
      purchasePrice: product.price,
      downPaymentPercent: savedSettings?.downpayment ?? 25,
      interestRate: product.latestMortgageRates.pmms30,
      loanTermYears: savedSettings?.loanterm ?? 30,
      monthlyRent: monthlyRent,
      vacancyRatePercent: product.rental_vacancy_rate ?? 6,
      maintenancePercent: 10,
      operatingExpensePercent: 10,
      appreciationRate: 3,
      holdingPeriodYears: 5,
      sellingCostsPercent: 6,
    });
    return {
      annualPreTaxCashFlow: results.annualPreTaxCashFlow,
      cashOnCashReturn: results.cashOnCashReturn,
      ROI: results.ROI,
      capRate: results.capRate,
    };
  };

  const buildNodes = (data) => {
    return data.map((product, index) => {
      const capRateResults = calcCaprate(product);
      const monthlyCashFlow = capRateResults
        ? capRateResults.annualPreTaxCashFlow / 12
        : 0;
        const capRate = capRateResults
        ? capRateResults.capRate
        : 0;
      return {
        key: index.toString(),
        label: (
          <div>
            <div className="property-node">
            <div className="thumbnail-container">
        <img
          src={product.thumbnail || defaultThumbnail}
          alt="Thumbnail"
          className="list-thumbnail-img mr-2"
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop in case the default image also fails
            e.target.src = defaultThumbnail;
          }}
        />
        {monthlyCashFlow !== 0 && (
          <div className={`status-dot ${monthlyCashFlow < 0 ? 'red-dot' : 'green-dot'}`}></div>
        )}
      </div>

              <div>
                <div className="address text-600 font-bold text-sm">
                  {product.fullAddress}
                </div>
                <div className="details text-14">
                  {/* {product.fullAddress} <br /> */}
                  {product.property_type || "-"} / {product.bedrooms} beds /{" "}
                  {product.bathrooms} baths / {product.squareFeet} Sq.Ft.
                </div>
                <div className="financial">                  
                  <span className="cf pr-1" style={getCashFlowStyle(monthlyCashFlow)}>
                  Cash-flow: ${formatCashFlow(monthlyCashFlow)}/mo 
                  </span>
                  <span className="cp pr-1" >
                  Cap Rate: %{capRate} 
                  </span>                   
                  ${formatPrice(product.price)}
                </div>
              </div>
            </div>
          </div>
        ),
        data: product,
        children: [], // Add children if needed
      };
    });
  };

  const filteredProducts = products.filter((product) => {
    return product.fullAddress.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const sortedProducts = filteredProducts.sort((a, b) => {
    if (!sortKey) return 0;

    let result = a[sortKey] < b[sortKey] ? -1 : a[sortKey] > b[sortKey] ? 1 : 0;
    return sortOrder * result;
  });

  const paginatedProducts = sortedProducts.slice(first, first + rows);
  const paginatedNodes = buildNodes(paginatedProducts);

  return (
    <div className="card">
      <Tree
        value={paginatedNodes}
        onNodeClick={(e) => onNodeSelect(e.node.data)}
      />
      <Paginator
        first={first}
        rows={rows}
        totalRecords={sortedProducts.length}
        rowsPerPageOptions={[5, 10, 20]}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
        }}
      />
    </div>
  );
};

export default ListView;
