import React, { useState, useEffect } from "react";
import http from "../http-common";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import {jwtDecode}   from "jwt-decode";
import { useAuth } from "./helpers/authcontext";
import { MultiSelect } from "primereact/multiselect";

const Account = () => {
  const { user, setUser } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  const [incometaxratePreference, setincometaxratePreference] = useState("");
  const [downpaymentPreference, setdownpaymentPreference] = useState("");
  const [loanTermPreference, setloanTermPreference] = useState("");
  const [holdperiodPreference, setholdperiodPreference] = useState("");
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);
  
  useEffect(() => {
    if (user) {
      setUsername(user.username || "");
      setName(user.name || "");
      setEmail(user.email || "");
      setPhone(user.phone || "");
      setAddress(user.address || "");

      const fetchUserSettings = async () => {
        try {
          const response = await http.get(`/user/${user.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          const settings = response.data?.settings;
          setincometaxratePreference(settings.taxrate || "");
          setdownpaymentPreference(settings.downpayment || "");
          setloanTermPreference(settings.loanterm || "");
          setholdperiodPreference(settings.holdperiod || "");
        } catch (error) {
          console.error("Error fetching user settings:", error);
        }
      };

      fetchUserSettings();
    }else {
      const savedSettings = JSON.parse(localStorage.getItem("userSettings"));
      if (savedSettings) {
        setincometaxratePreference(savedSettings.taxrate || "");
        setdownpaymentPreference(savedSettings.downpayment || "");
        setloanTermPreference(savedSettings.loanterm || "");
        setholdperiodPreference(savedSettings.holdperiod || "");
      }
    }
  }, [user]);

  const propertyTypeOptions = [
    { label: 'Single Family Home', value: '1' },
    { label: 'Condo', value: '2' },
    { label: 'Townhouse', value: '3' },
    { label: 'Multi-Family', value: '4' }
  ];

  const handleSubmit = async () => {
    try {
      const response = await http.put(
        "/user/profile",
        {
          username,
          password,
          name,
          email,
          phone,
          address,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const { token } = response.data;
      localStorage.setItem("token", token);
      const decodedToken = jwtDecode(token);
      setUser(decodedToken);
      console.log("Profile updated successfully");
    } catch (error) {
      console.error("Profile update failed:", error);
    }
  };

  const handleChangePassword = async () => {
    try {
      const response = await http.put(
        "/user/change-password",
        {
          password: newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const { token } = response.data;
      localStorage.setItem("token", token);
      const decodedToken = jwtDecode(token);
      setUser(decodedToken);
      console.log("Password updated successfully");
      setShowPasswordDialog(false);
      setNewPassword("");
    } catch (error) {
      console.error("Password update failed:", error);
    }
  };

  const handleSettingsSubmit = async () => {
    try {
      const response = await http.put(
        "/user/settings",
        {
          userId: user.id,
          incometaxrate: incometaxratePreference,
          downpayment: downpaymentPreference,
          loanterm: loanTermPreference,
          holdperiod: holdperiodPreference,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const settings = response.data?.settings;
      localStorage.setItem("settings", JSON.stringify(settings));             
      console.log("Settings updated successfully");
    } catch (error) {
      console.error("Settings update failed:", error);
    }
  };

  const handlePropertyTypeChange = (e) => {
    setSelectedPropertyTypes(e.value); // Update property types array directly
  };

  return (
    <div className="flex align-items-center justify-content-center ">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6 mt-10">
        <TabView>
          <TabPanel header="Profile">
            <div className="text-center mb-5">
              <div className="text-900 text-3xl font-medium mb-3">
                Manage Your Profile
              </div>
            </div>

            <div>
              <label htmlFor="email" className="block text-900 font-medium mb-2">
                Email
              </label>
              <InputText
                id="email"
                type="text"
                placeholder="Email address"
                className="w-full mb-2"
                value={username}
                disabled
              />

              <div className="flex align-items-center mb-3">
                <div className="flex-grow-1 mr-2">
                  <label htmlFor="password" className="block text-900 font-medium mb-2">
                    Password
                  </label>
                  <InputText
                    id="password"
                    type="password"
                    placeholder="Password"
                    className="w-full mb-3"
                    value={password}
                    disabled
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <Button
                  label="Edit"
                  icon="pi pi-key"
                  className="p-button-secondary btn-chgpwd"
                  onClick={() => setShowPasswordDialog(true)}
                />
              </div>

              {/* <label htmlFor="phone" className="block text-900 font-medium mb-2">
                Phone Number
              </label>
              <InputText
                id="phone"
                type="text"
                placeholder="Phone Number"
                className="w-full mb-3"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />

              <label htmlFor="address" className="block text-900 font-medium mb-2">
                Address
              </label>
              <InputText
                id="address"
                type="text"
                placeholder="Address"
                className="w-full mb-3"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              /> */}

              <Button
                label="Save Profile"
                icon="pi pi-save"
                className="w-full"
                onClick={handleSubmit}
              />
            </div>
          </TabPanel>
          <TabPanel header="Settings">
            <div className="grid">
              <div className="col-12 md:col-6">
                <label htmlFor="incometaxratePreference" className="block text-900 font-medium mb-1">
                  Income Tax Rate
                </label>
                <InputText
                  id="incometaxratePreference"
                  type="text"
                  placeholder="Income tax rate"
                  className="w-full"
                  value={incometaxratePreference}
                  onChange={(e) => setincometaxratePreference(e.target.value)}
                />
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="downpaymentPreference" className="block text-900 font-medium mb-1">
                  Down Payment %
                </label>
                <InputText
                  id="downpaymentPreference"
                  type="text"
                  placeholder="Down payment"
                  className="w-full"
                  value={downpaymentPreference}
                  onChange={(e) => setdownpaymentPreference(e.target.value)}
                />
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="loantermPreference" className="block text-900 font-medium mb-1">
                  Loan Term
                </label>
                <InputText
                  id="loantermPreference"
                  type="text"
                  placeholder="30"
                  className="w-full"
                  value={loanTermPreference}
                  onChange={(e) => setloanTermPreference(e.target.value)}
                />
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="holdperiodPreference" className="block text-900 font-medium mb-1">
                Holding Period
                </label>
                <InputText
                  id="holdperiodPreference"
                  type="text"
                  placeholder="30"
                  className="w-full"
                  value={holdperiodPreference}
                  onChange={(e) => setholdperiodPreference(e.target.value)}
                />
              </div>

              <div className="field col-12 md:col-6">
              <label htmlFor="holdperiodPreference" className="block text-900 font-medium mb-1">
                Property Type
                </label>
            <MultiSelect
              value={selectedPropertyTypes}
              options={propertyTypeOptions}
              onChange={handlePropertyTypeChange}
              placeholder="Select Property Types"
              className="w-full"
              display="chip" // Optional: displays selected items as chips
            />
          </div>

              <div className="col-12">
                <Button
                  label="Save Settings"
                  icon="pi pi-save"
                  className="w-full"
                  onClick={handleSettingsSubmit}
                />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>

      <Dialog
        header="Change Password"
        visible={showPasswordDialog}
        style={{ width: "50vw" }}
        onHide={() => setShowPasswordDialog(false)}
      >
        <div>
          <label htmlFor="newPassword" className="block text-900 font-medium mb-2">
            New Password
          </label>
          <InputText
            id="newPassword"
            type="password"
            placeholder="New Password"
            className="w-full mb-3"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button
            label="Update Password"
            icon="pi pi-save"
            className="w-full"
            onClick={handleChangePassword}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default Account;
