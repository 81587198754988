import React, { useEffect } from "react";
import Pricing from "./helpers/pricing";

const PricingPage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://crm.zoho.com/crm/WebFormServeServlet?rid=da82b16635b2c865bcf8efaf231c629032d9b4c97f259a05dde71a0c5cf0f54a85100a8a0ccfdf3147aea514323e5137gidb2e76d05aea2e9a2765f290ea717bfd2edadb88a0babca6933b210f6b375f555&script=$sYG";
    script.async = true;
    // document.getElementById('zoho-form').appendChild(script);
  }, []);


  return (
    <>
      <div className="p-grid p-nogutter landing-page">
        <div className="p-col-12 p-md-6 p-lg-6 left-section">
          <h1 className="headline">Simple, transparent pricing</h1>
        </div>
      </div>
      <Pricing />
    </>
  );
};

export default PricingPage;
