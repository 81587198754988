import React from 'react';

const ZohoJoinNowForm = () => {
  return (
    <div className="iframe-container">
    <iframe
      frameborder="0"
      id="iframewin"
      width="100%"
      height="100%"
      src="https://zsjo-cmpzourl.maillist-manage.com/ua/Optin?od=11287ecca96b3e&zx=12a496098&tD=11213ca2a3cf0a7a9&sD=11213ca2a3cf0ab99"      
    ></iframe>
  </div>
  );
};

export default ZohoJoinNowForm;
