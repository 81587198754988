import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";
import StreetLink from "./helpers/streetlink.component";
import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { withRouter } from "../common/with-router";

class LinksList extends Component {
  constructor(props) {
    super(props);
    this.retrieveRecentLinks = this.retrieveRecentLinks.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.searchTitle = this.searchTitle.bind(this);
    this.onChangeSearchLinkid = this.onChangeSearchLinkid.bind(this);
    this.onChangeSearchLinkText = this.onChangeSearchLinkText.bind(this);
    this.setCurrentIndex = this.setCurrentIndex.bind(this);
    


    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: 0,
      searchTitle: "",
      searchLinkedId: "",
      linkId: "",
    };
  }

  onChangeSearchLinkText(e) {
    const searchTitle = e.target.value;

    this.setState({
      linkId: searchTitle,
    });
  }

  setCurrentIndex(i){
    this.setState({
      currentIndex: i,
    });
  }

  componentDidMount() {
    const id = this.props.router.params.id;
    if (id) {
      
      this.setState({
        linkId: id,
      });
      TutorialDataService.getLink(id)
        .then((response) => {
          this.setState({
            tutorials: [response.data[0]],
            currentIndex: 0,
          });
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else this.retrieveRecentLinks();
  }

  onChangeSearchLinkid() {
    const searchTitle = this.state.linkId;
    if (searchTitle) {
      this.setState({
        tutorials: [],
        currentTutorial: [],
      });
      TutorialDataService.getLink(searchTitle)
        .then((response) => {
          this.setState({
            tutorials: [response.data[0]],
            currentIndex: 0,
          });
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  retrieveRecentLinks() {
    TutorialDataService.getTopRecentLinks()
      .then((response) => {
        this.setState({
          tutorials: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieveLinksById(linkedId, index) {
    TutorialDataService.getLink(linkedId)
      .then((response) => {
        this.setState({
          currentTutorial: response.data,
          currentIndex: index,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  setActiveTutorial = (linkedid, index) => {
    this.retrieveLinksById(linkedid, index);
  };

  searchTitle() {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });

    const paracall = [];

    TutorialDataService.makeInventoryRequest(paracall)
      .then((response) => {
        this.setState({
          tutorials: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { tutorials, currentTutorial, currentIndex, linkId } = this.state;
    const createDynamicTabs = () => {
      return tutorials.map((tab, i) => {
        const linkedid = tab.linkedid;
        return (
          <AccordionTab
            key={tab.linkedid}
            header={tab.linkedid}
            activeIndex={currentIndex} 
            onChange={() => this.setCurrentIndex(i)}
            onClick={() => this.setActiveTutorial(linkedid, i)}
          >
            {currentTutorial &&
              currentTutorial.map((tutorial, index) => (
                <li className={"list-group-item "}>
                  <div>
                    {" "}
                    <StreetLink
                      url={tutorial.url}
                      street={
                        tutorial.streetNumber +
                        " " +
                        tutorial.street +
                        ", " +
                        tutorial.city +
                        ", " +
                        tutorial.state +
                        " " +
                        tutorial.zipCode
                      }
                    />
                  </div>
                  <div>
                    Price: {tutorial.price} | Rental: {tutorial.rentalestimate ?? 'N/A'} | FMR: {tutorial.fmr ?? 'N/A'} | source: {tutorial.source}{" "}
                  </div>
                  <div>{moment(tutorial.updateddate).format("LL")}</div>
                </li>
              ))}
          </AccordionTab>
        );
      });
    };
    return (
      <div className="container ">
        <div className="list row">
          <div className="col-md-12">
            <div className="text-600 font-bold text-6xl mb-4 text-center">
              Links List{" "}
            </div>
            <div className="row">
              <div className="col-md-8">
                <InputText
                  placeholder="Link Id"
                  value={linkId}
                  className="w-full"
                  onChange={this.onChangeSearchLinkText}
                />
              </div>
              <div className="col-md-4">
                <Button
                  label="Apply"
                  onClick={this.onChangeSearchLinkid}
                  className="p-button w-full"
                />
              </div>
            </div>
            <div className="card">
              <Accordion>{createDynamicTabs()}</Accordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LinksList);
