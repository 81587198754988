import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";
import Autocomplete from "react-google-autocomplete";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import RentVsBuyChart from "./helpers/rentvsbuycalc";

export default class TutorialsList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveTutorials = this.retrieveTutorials.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.removeAllTutorials = this.removeAllTutorials.bind(this);
    this.searchTitle = this.searchTitle.bind(this);

    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    //this.retrieveTutorials();
  }

  // Function to parse address components
  parseAddressComponents(components) {
    const address = {};
    if (components && components.length > 0) {
      components.forEach((component) => {
        const types = component.types;
        const longName = component.long_name;
        if (types.includes("street_number")) {
          address.streetNumber = longName;
        } else if (types.includes("route")) {
          address.street = this.formatStreet(longName);
        } else if (types.includes("locality")) {
          address.city = longName;
        } else if (types.includes("administrative_area_level_1")) {
          address.state = longName;
        } else if (types.includes("postal_code")) {
          address.zipcode = longName;
        }
      });
    } else {
      console.log("components is undefined or empty");
    }
    return address;
  }

  formatStreet(street) {
    const streetWords = street.split(" ");

    let formattedStreet;

    switch (streetWords.length) {
      case 2:
        formattedStreet = streetWords[0]; // "123"
        break;
      case 3:
        formattedStreet = `${streetWords[0]} ${streetWords[1]}`; // "123 Main"
        break;
      case 4:
        formattedStreet = `${streetWords[0]} ${streetWords[1]} ${streetWords[2]}`; // "123 Main Street"
        break;
      case 5:
        formattedStreet = `${streetWords[0]} ${streetWords[1]} ${streetWords[2]} ${streetWords[3]}`; // "123 Main Street Apartment"
        break;
      default:
        // Handle other cases as needed
        break;
    }

    return formattedStreet;
  }

  onChangeSearchTitle(e) {
    const searchTitle = e;

    this.setState({
      searchTitle: searchTitle,
      isLoading: true,
    });

    this.searchTitle(e.address_components);
  }

  retrieveTutorials() {
    TutorialDataService.getAll()
      .then((response) => {
        this.setState({
          tutorials: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveTutorials();
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveTutorial(tutorial, index) {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index,
    });
  }

  removeAllTutorials() {
    TutorialDataService.deleteAll()
      .then((response) => {
        console.log(response.data);
        this.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  searchTitle() {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });

    const paracall = this.parseAddressComponents(
      this.state.searchTitle?.address_components
    );

    TutorialDataService.makeInventoryRequest(paracall)
      .then((response) => {
        this.setState({
          tutorials: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  searchTitle(address_components) {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });

    const paracall = this.parseAddressComponents(address_components);

    TutorialDataService.makeInventoryRequest(paracall)
      .then((response) => {
        this.setState({
          tutorials: response.data,
          isLoading: false,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          isLoading: false,
        });
      });
  }

  parseSearchText(searchText) {
    const addressComponents = searchText
      .split(",")
      .map((component) => component.trim());
    const parsedAddress = {};

    // Extract streetNumber and street
    const streetAddress = addressComponents.shift().split(" ");
    parsedAddress.streetNumber = streetAddress.shift();
    parsedAddress.street = streetAddress.join(" ");

    // Extract city, state, and zipCode
    const cityStateZip = addressComponents.pop().split(" ");
    parsedAddress.city = addressComponents.join(", ");
    parsedAddress.state = cityStateZip.shift();
    parsedAddress.zipcode = cityStateZip.join(" ");

    return parsedAddress;
  }

  render() {
    const { tutorials, currentTutorial, currentIndex, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="spinner-container">
          <ProgressSpinner />
        </div>
      ); // Show spinner when loading
    }

    return (
      <div className="container mt-3">
        <div className="list row">
          <div className="col-md-12">
            <div className="input-group mb-3">
              <Autocomplete
                className="form-control pac-target-input"
                apiKey="AIzaSyBrqfpgLEI9L-coO0abNL3pfjaS2F2TKfM"
                onPlaceSelected={(place) => {
                  this.onChangeSearchTitle(place);
                }}
                options={{
                  types: ["address"],
                  fields: ["address_components"],
                  componentRestrictions: { country: "us" },
                }}
              />
            </div>
          </div>
          <div className="col-md-8">
            <h4>Address List</h4>
            <ul className="list-group">
              {tutorials.map((tutorial, index) => (
                <li
                  className={`list-group-item ${
                    index === currentIndex ? "active" : ""
                  }`}
                  onClick={() => this.setActiveTutorial(tutorial, index)}
                  key={index}
                >
                  <div>
                    {tutorial.streetNumber} {tutorial.street} {tutorial.city}{" "}
                    {tutorial.state} {tutorial.zipCode}
                  </div>
                  <div>
                    beds - {tutorial.bedrooms} | baths - {tutorial.bathrooms} |
                    sqFt - {tutorial.squareFeet} | year - {tutorial.year_built}
                  </div>
                  <div>
                    Price - {tutorial.price} | {tutorial.source} |{" "}
                    {tutorial.status}
                  </div>
                  <div>
                    Rent - {tutorial.rentalestimate} | Fmr - {tutorial.fmr}
                  </div>
                  <div>{moment(tutorial.updateddate).format("LL")}</div>
                </li>
              ))}
              {tutorials.length === 0 && (
                <li className="list-group-item">No results found.</li>
              )}
            </ul>
          </div>
          <div className="col-md-4 mt-4">
            {currentTutorial ? (
              <div>
                <h4>Details</h4>
                <div className="col-md-4 mt-4">
                  <label>
                    <strong>Listing Deails:</strong>
                  </label>{" "}
                  <div>
                    beds - {currentTutorial.bedrooms} | baths -{" "}
                    {currentTutorial.bathrooms} | sqFt -{" "}
                    {currentTutorial.squareFeet} | year -{" "}
                    {currentTutorial.year_built}
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <label>
                    <strong>Status:</strong>
                  </label>{" "}
                  {currentTutorial.status}
                </div>
                <div className="col-md-4 mt-2">
                  <label>
                    <strong>Linked Inventory:</strong>
                  </label>{" "}
                  <a
                    href={"/links/" + currentTutorial.linkedid}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {currentTutorial.linkedid}
                  </a>
                </div>

              </div>
            ) : (
              <div>
                <br />
                <p>Please click on a address...</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
