// utils.js
export const formatPrice = (price) => {
    const number = parseFloat(price?.replace(/[^\d.-]/g, '')); // Remove non-numeric characters
  
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(2) + 'B';
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(2) + 'M';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(2) + 'K';
    } else {
      return number.toFixed(2);
    }
  };
  