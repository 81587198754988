import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

const PropertyManagersTable = () => {
  const [propertyManagers, setPropertyManagers] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const [searchState, setSearchState] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(20);
  const [first, setFirst] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchPropertyManagers();
  }, [first, rows, sortField, sortOrder]);

  const fetchPropertyManagers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://ipmc-api.onrender.com/api/lookup/property-manager?limit=${rows}&offset=${first}${
          sortOrder ? `&sort=${sortOrder === 1 ? "desc" : "asc"}` : ""
        }`
      );
      const data = await response.json();
      setPropertyManagers(data.data);
      setTotalRecords(data.total);
      setLoading(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const navigate = useNavigate(); // Updated hook for navigation

  const fetchPropertyManagersByCityAndState = async (city, state) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://ipmc-api.onrender.com/api/lookup/property-manager/search/?city=${city}&state=${state}`
      );
      const data = await response.json();
      setPropertyManagers(data);
      setTotalRecords(data.length);
      setLoading(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const onSearch = () => {
    if (searchCity.trim() !== "" || searchState.trim() !== "") {
      fetchPropertyManagersByCityAndState(searchCity.trim(), searchState.trim());
    } else {
      fetchPropertyManagers();
    }
  };

  const onPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const onSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
  };

  return (
    <div className="datatable-filter-demo">
      <div className="card m-2">
        <h3>Property Managers</h3>
        <div className="p-grid p-justify-between p-ai-center mb-3 pl-1">
          <div className="p-col">
            <InputText
              value={searchCity}
              onChange={(e) => setSearchCity(e.target.value)}
              placeholder="Search by City"
              className="mr-2"
            />
            <InputText
              value={searchState}
              onChange={(e) => setSearchState(e.target.value)}
              placeholder="Search by State"
            />
            <Button label="Search" icon="pi pi-search" onClick={onSearch} />
          </div>
        </div>
        <Toast ref={toast} />
        <DataTable
          value={propertyManagers}
          paginator
          rows={rows}
          first={first}
          totalRecords={totalRecords}
          lazy
          loading={loading}
          responsiveLayout="scroll"
          onPage={onPage}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
          className="mb-3 pl-1"
        >
          <Column field="name" header="Name" sortable></Column>
          <Column field="company" header="Company"></Column>
          <Column field="city" header="City"></Column>
          <Column field="state" header="State" ></Column>
          <Column field="address" header="Address"></Column>
          <Column field="phone" header="Phone"></Column>
          <Column field="fax" header="Fax"></Column>
          <Column field="email" header="Email"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default PropertyManagersTable;
