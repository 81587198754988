import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import TutorialDataService from "../services/tutorial.service";
import { useNavigate  } from 'react-router-dom';

const RentalVacancyRatesTable = () => {
  const [vacancyRates, setVacancyRates] = useState([]);
  const [searchZip, setSearchZip] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(20);
  const [first, setFirst] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchVacancyRates();
  }, [first, rows, sortField, sortOrder]);

  const fetchVacancyRates = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://ipmc-api.onrender.com/api/lookup/vacancy?limit=${rows}&offset=${first}${
          sortOrder ? `&sort=${sortOrder === 1 ? "asc" : "desc"}` : ""
        }`
      );
      const data = await response.json();
      setVacancyRates(data.data);
      setTotalRecords(data.total);
      setLoading(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
      setLoading(false);
    }
  };
  const navigate = useNavigate(); // Updated hook for navigation

  const navigateToExtracts = (zip) => {
    // Navigate to the extracts page with the ZIP code
    navigate(`/leads?zip=${zip}`);
  };

  const zipBodyTemplate = (rowData) => {
    return (
      <a href={`/extracts/${rowData.zip}`} onClick={(e) => {
        e.preventDefault();
        navigateToExtracts(rowData.zip);
      }}>
        {rowData.zip}
      </a>
    );
  };

  const fetchVacancyRatesByZip = async (zip) => {
    setLoading(true);
    try {
      TutorialDataService.getRentalVacancyRateByZip(zip)
        .then((data) => {
          setVacancyRates(data.data.data);
          setTotalRecords(1);
          setLoading(false);
          console.log(data.data.data);
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to fetch data",
            life: 3000,
          });
          setLoading(false);
        });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const onSearch = () => {
    if (searchZip.trim() !== "") {
      fetchVacancyRatesByZip(searchZip.trim());
    } else {
      fetchVacancyRates();
    }
  };

  const onPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const onSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
  };

  return (
    <div className="datatable-filter-demo">
      <div className="card m-2">
        <h3>Rental Vacancy Rates</h3>
        <div className="p-grid p-justify-between p-ai-center mb-3 pl-1">
          <div className="p-col">
            <InputText
              value={searchZip}
              onChange={(e) => setSearchZip(e.target.value)}
              placeholder="Search by ZIP code"
            />
            <Button label="Search" icon="pi pi-search" onClick={onSearch} />
          </div>
        </div>
        <Toast ref={toast} />
        <DataTable
          value={vacancyRates}
          paginator
          rows={rows}
          first={first}
          totalRecords={totalRecords}
          lazy
          loading={loading}
          responsiveLayout="scroll"
          onPage={onPage}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
          className="mb-3 pl-1"
        >
          <Column field="zip" header="ZIP Code"  body={zipBodyTemplate}></Column>
          <Column
            field="total_housing_count"
            header="Total Housing Units"            
          ></Column>
          <Column
            field="rental_vacancy_rate"
            header="Vacancy Rate (%)"
            sortable
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default RentalVacancyRatesTable;
