import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from "primereact/divider";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RentVsBuyChart = ({ price, rentalEstimate, selectedRowData }) => {
  const [inputs, setInputs] = useState({
    price: price,
    rentalEstimate: rentalEstimate,
    mortgageRate: selectedRowData?.latestMortgageRates?.pmms30 ?? 6.6,
    mortgageTerm: 30,
    propertyTaxRate: (selectedRowData?.propertyTaxes?.Property_Tax_Rate).replace("%","")?? 1,
    maintenanceCost: 1,
    homeAppreciationRate: 3,
    rentIncreaseRate: 2,
  });

  const handleInputChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  // Calculations
  const monthlyMortgage =
    (inputs.price * (inputs.mortgageRate / 100)) /
    12 /
    (1 -
      Math.pow(1 + inputs.mortgageRate / 100 / 12, -inputs.mortgageTerm * 12));
  const annualPropertyTax = inputs.price * (inputs.propertyTaxRate / 100);
  const annualMaintenance = inputs.price * (inputs.maintenanceCost / 100);
  const totalAnnualCostBuy =
    monthlyMortgage * 12 + annualPropertyTax + annualMaintenance;

    const projection = () => {
      let buyTotal = 0, rentTotal = 0, homeValue = inputs.price, currentRent = inputs.rentalEstimate;
      const years = [0, 1, 2, 3, 4, 5];
      const buyTotals = [0], rentTotals = [0], homeValues = [inputs.price];
      const yearlyData = [];
  
      for (let year = 1; year <= 5; year++) {
        buyTotal += totalAnnualCostBuy;
        rentTotal += currentRent * 12;
        homeValue *= (1 + inputs.homeAppreciationRate / 100);
        currentRent *= (1 + inputs.rentIncreaseRate / 100);
        
        buyTotals.push(buyTotal);
        rentTotals.push(rentTotal);
        homeValues.push(homeValue);
  
        yearlyData.push({
          year,
          buyTotal: buyTotal,
          rentTotal: rentTotal,
          homeValue: homeValue,
          netBuyCost: buyTotal - (homeValue - inputs.price)
        });
      }
  
      return { years, buyTotals, rentTotals, homeValues, yearlyData };
    };
  
    const { years, buyTotals, rentTotals, homeValues, yearlyData } = projection();

   // Find breakeven point
   const findBreakEvenPoint = () => {
    let breakEvenYear = null;
    for (let i = 0; i < yearlyData.length; i++) {
      if (yearlyData[i].netBuyCost < yearlyData[i].rentTotal) {
        breakEvenYear = yearlyData[i].year;
        break;
      }
    }
    return breakEvenYear;
  };

  const breakEvenYear = findBreakEvenPoint();

  const data = {
    labels: years,
    datasets: [
      {
        label: "Cost of Buying",
        data: buyTotals,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Cost of Renting",
        data: rentTotals,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Home Value",
        data: homeValues,
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top", labels: { boxWidth: 10, font: { size: 10 } } },
      title: {
        display: true,
        text: "5-Year Rent vs. Buy Comparison",
        font: { size: 14 },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { display: false },
        ticks: { font: { size: 10 } },
      },
      x: {
        title: { display: false },
        ticks: { font: { size: 10 } },
      },
    },
  };

  const getExplanationText = () => {
    const finalBuyTotal = buyTotals[buyTotals.length - 1];
    const finalRentTotal = rentTotals[rentTotals.length - 1];
    const finalHomeValue = homeValues[homeValues.length - 1];
    const netBuyCost = finalBuyTotal - (finalHomeValue - inputs.price);
    const buyOrRentDifference = netBuyCost - finalRentTotal;

    return (
      <div>
        <p>
          <strong>Monthly Mortgage Payment:</strong> $
          {monthlyMortgage.toFixed(2)}
        </p>
        <p>
          This is calculated based on a home price of $
          {inputs.price.toLocaleString()}, a mortgage rate of{" "}
          {inputs.mortgageRate}%, and a term of {inputs.mortgageTerm} years.
        </p>

        <p>
          <strong>Annual Property Tax:</strong> ${annualPropertyTax.toFixed(2)}
        </p>
        <p>This is {inputs.propertyTaxRate}% of the home value.</p>

        <p>
          <strong>Annual Maintenance Cost:</strong> $
          {annualMaintenance.toFixed(2)}
        </p>
        <p>This is estimated at {inputs.maintenanceCost}% of the home value.</p>

        <p>
          <strong>Total Annual Cost of Buying:</strong> $
          {totalAnnualCostBuy.toFixed(2)}
        </p>
        <p>
          This includes mortgage payments, property tax, and maintenance costs.
        </p>

        <Divider />
        
        <h4>Year-by-Year Breakdown</h4>
        <DataTable value={yearlyData} responsiveLayout="scroll">
          <Column field="year" header="Year" />
          <Column field="buyTotal" header="Buy Total" body={(rowData) => `$${rowData.buyTotal.toLocaleString()}`} />
          <Column field="rentTotal" header="Rent Total" body={(rowData) => `$${rowData.rentTotal.toLocaleString()}`} />
          <Column field="homeValue" header="Home Value" body={(rowData) => `$${rowData.homeValue.toLocaleString()}`} />
          <Column field="netBuyCost" header="Net Buy Cost" body={(rowData) => `$${rowData.netBuyCost.toLocaleString()}`} />
        </DataTable>

        <Divider />
        
        <h4>Breakeven Analysis</h4>
        {breakEvenYear ? (
          <p>Based on these projections, buying becomes more cost-effective than renting in year {breakEvenYear}. 
             This is when the net cost of buying (total costs minus home value appreciation) becomes less than the total cost of renting.</p>
        ) : (
          <p>Based on these projections, buying does not become more cost-effective than renting within the 5-year period. 
             You may want to consider a longer time horizon or different market conditions for buying to potentially become more advantageous.</p>
        )}
        
        <p><strong>Factors to Consider:</strong></p>
        <ul>
          <li>This analysis doesn't account for potential tax benefits of homeownership, such as mortgage interest deductions.</li>
          <li>The breakeven point is sensitive to assumptions about home value appreciation and rent increases.</li>
          <li>Non-financial factors, such as stability, freedom to modify your living space, and potential pride of ownership, are not quantified here.</li>
          <li>Longer time horizons often favor buying, as mortgage payments remain stable while rents typically increase over time.</li>
        </ul>
        
        <p><strong>Conclusion:</strong> {breakEvenYear ? 
          `While renting may be cheaper in the short term, buying becomes more cost-effective after ${breakEvenYear} years in this scenario.` :
          `Renting appears more cost-effective throughout the 5-year period in this scenario. Consider how a longer time horizon might change this outcome.`} 
           Always consult with a financial advisor for personalized advice, as individual circumstances can greatly affect the rent vs. buy decision.</p>
      


        <Divider />

        <p>
          <strong>After 5 Years:</strong>
        </p>
        <p>Total cost of buying: ${finalBuyTotal.toLocaleString()}</p>
        <p>Total cost of renting: ${finalRentTotal.toLocaleString()}</p>
        <p>Estimated home value: ${finalHomeValue.toLocaleString()}</p>

        <p>
          <strong>Net cost of buying:</strong> ${netBuyCost.toLocaleString()}
        </p>
        <p>
          This is the total cost of buying minus the increase in home value.
        </p>

        <p>
          <strong>Difference between buying and renting:</strong> $
          {Math.abs(buyOrRentDifference).toLocaleString()}
        </p>
        <p>
          {buyOrRentDifference > 0
            ? `Renting is cheaper by $${buyOrRentDifference.toLocaleString()} over 5 years.`
            : `Buying is cheaper by $${Math.abs(
                buyOrRentDifference
              ).toLocaleString()} over 5 years.`}
        </p>

        <p>
          <strong>Conclusion:</strong> Based on these projections,
          {buyOrRentDifference > 0 ? " renting " : " buying "}
          appears to be the more cost-effective option over a 5-year period.
          However, keep in mind that this analysis doesn't account for factors
          like tax deductions for mortgage interest, the potential for faster
          appreciation or depreciation in the housing market, or the intangible
          benefits of homeownership.
        </p>
      </div>
    );
  };

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-8">
        <Card title="5-Year Rent vs. Buy Comparison">
          <div style={{ height: "400px", width: "100%" }}>
            <Line options={options} data={data} />
          </div>
        </Card>
        <Card title="Analysis">{getExplanationText()}</Card>
      </div>
      <div className="p-col-12 p-md-4">
        <Panel header="Input Parameters">
          <div className="p-fluid">
            <div className="p-field">
              <label htmlFor="price">Home Price ($)</label>
              <InputNumber
                id="price"
                value={inputs.price}
                onValueChange={(e) => handleInputChange("price", e.value)}
                mode="currency"
                currency="USD"
                locale="en-US"
              />
            </div>
            <div className="p-field">
              <label htmlFor="rentalEstimate">Monthly Rent Estimate ($)</label>
              <InputNumber
                id="rentalEstimate"
                value={inputs.rentalEstimate}
                onValueChange={(e) =>
                  handleInputChange("rentalEstimate", e.value)
                }
                mode="currency"
                currency="USD"
                locale="en-US"
              />
            </div>
            <div className="p-field">
              <label htmlFor="mortgageRate">Mortgage Rate (%)</label>
              <InputNumber
                id="mortgageRate"
                value={inputs.mortgageRate}
                onValueChange={(e) =>
                  handleInputChange("mortgageRate", e.value)
                }
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                suffix="%"
              />
            </div>
            <div className="p-field">
              <label htmlFor="mortgageTerm">Mortgage Term (years)</label>
              <InputNumber
                id="mortgageTerm"
                value={inputs.mortgageTerm}
                onValueChange={(e) =>
                  handleInputChange("mortgageTerm", e.value)
                }
              />
            </div>
            <div className="p-field">
              <label htmlFor="propertyTaxRate">Property Tax Rate (%)</label>
              <InputNumber
                id="propertyTaxRate"
                value={inputs.propertyTaxRate}
                onValueChange={(e) =>
                  handleInputChange("propertyTaxRate", e.value)
                }
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                suffix="%"
              />
            </div>
            <div className="p-field">
              <label htmlFor="maintenanceCost">Maintenance Cost (%)</label>
              <InputNumber
                id="maintenanceCost"
                value={inputs.maintenanceCost}
                onValueChange={(e) =>
                  handleInputChange("maintenanceCost", e.value)
                }
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                suffix="%"
              />
            </div>
            <div className="p-field">
              <label htmlFor="homeAppreciationRate">
                Home Appreciation Rate (%)
              </label>
              <InputNumber
                id="homeAppreciationRate"
                value={inputs.homeAppreciationRate}
                onValueChange={(e) =>
                  handleInputChange("homeAppreciationRate", e.value)
                }
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                suffix="%"
              />
            </div>
            <div className="p-field">
              <label htmlFor="rentIncreaseRate">Rent Increase Rate (%)</label>
              <InputNumber
                id="rentIncreaseRate"
                value={inputs.rentIncreaseRate}
                onValueChange={(e) =>
                  handleInputChange("rentIncreaseRate", e.value)
                }
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                suffix="%"
              />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default RentVsBuyChart;

