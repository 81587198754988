import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useNavigate, useLocation } from "react-router-dom";
import ZohoJoinNowForm from './helpers/zohojoinnowform';
import ZohoForm from './helpers/zohojoinnowform';
import Pricing from './helpers/pricing';


const LandingPage = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://crm.zoho.com/crm/WebFormServeServlet?rid=da82b16635b2c865bcf8efaf231c629032d9b4c97f259a05dde71a0c5cf0f54a85100a8a0ccfdf3147aea514323e5137gidb2e76d05aea2e9a2765f290ea717bfd2edadb88a0babca6933b210f6b375f555&script=$sYG';
        script.async = true;
        // document.getElementById('zoho-form').appendChild(script);
      }, []);

      

    const navigate = useNavigate();

    const navigatetoregistration= () => {
        navigate("/register");
    }
    return (
        <>
        <div className="p-grid p-nogutter landing-page">
            <div className="p-col-12 p-md-6 p-lg-6 left-section">
                <h1 className="headline">Supercharge Your Next Rental Property Research</h1>
                <p className="description">
                    Discover the best rental properties with ease. Leverage our advanced tools and insights to maximize your cashflow.
                </p>
                <div id="zoho-form" className="zoho-form-container">
                    <ZohoJoinNowForm/>
                </div>
                
            </div>   
                
        </div>
        <Pricing/>     
        </>
    );
};

export default LandingPage;
